@import 'theme';
@import 'mixin';

/*** Printer Card *****/
.printer-card {
  margin-top: 1.5em;

  .card {
    .card-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .title-box {
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;

        .printer-name, .print-filename {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          text-decoration: underline;
        }
      }
      .primary-title {
        font-weight: bolder;
      }
      .secondary-title {
        font-size: 0.875em;
      }
    }
    .card-body {
      .container-fluid {
        padding: 0px;
      }

      &.failure-alert {
        button {
          color: var(--color-on-warning);
          border-color: var(--color-on-warning);
          &:hover {
            color: var(--color-on-warning-2);
            background-color: var(--color-on-warning);
          }
        }
      }
    }

    button.info-section-toggle {
      width: 33px;
      height: 33px;
      position: relative;
      color: var(--color-divider);
      font-size: 1rem;
      padding: 0rem 1.5rem;
      i {
        position: absolute;
        transform: translate(-50%, -50%);
      }
      &.pressed {
        color: var(--color-primary);
        border-bottom: solid thin var(--color-primary);
      }
    }

    .printer-controls {
      display: flex;
      flex-flow: row nowrap;

      .btn {
        width: 60px !important;
        height: 60px !important;
        margin: 8px !important;
        background: var(--color-primary);
        color: var(--color-on-primary);
      }

      .xy-controls {
        flex: 0 1 65%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;

        .x-controls {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-evenly;
          align-items: center;
        }
      }

      .z-controls {
        display: flex;
        flex: 0 5 35%;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: center;
      }
    }

    .control-options {
      .btn-group-toggle {
        width: 100%;
        .btn {
          border: solid thin;
          &.active {
            background: var(--color-primary);
          }
        }
      }
    }
  }

  .overlay {
    position: relative;
    .overlay-top {
      position: absolute;
      z-index: 1;
    }
  }

  .overlay:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height:100%;
      top: 0;
      left: 0;
      background: var(--color-overlay);
  }
}

/**** printer-acted */
.printer-acted {
  text-align: center;
}

/*** timelapse-gallery  */

.timelapse-gallery {

  margin-top: 1.5rem;

  .card-body {
    position: relative;
  }

  .card-img-top {
    position: relative;
    background-color: black;

    a.fullscreen-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.5rem;
      background-color: rgba(0,0,0,0.7);
      color: rgba(255,255,255,0.5);
    }
  }
  #tl-fullscreen-modal {
    .modal-full {
      min-width: 100%;
      margin: 0;
    }

    .modal-full .modal-content {
      min-height: 100vh;

      .modal-body {
        padding: 0;

        .video-player {
          height: calc(100vh - 251px);
        }
      }
    }
  }
  .hints {
    font-size: smaller;
    margin-bottom: 1em;
  }

  .timelapse-card {
    margin-top: 0.75em;
  }

  .pagination {
    margin-bottom: 0;
    margin-top: 15px;
  }

  .alert-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem;
    display: none;
  }

  .card-body.plain-view {
    min-height: 10em;
  }

  img.toggle-icon {
    width: 20px;
    height: 20px;
  }
  .card-img {
    margin: 31px 0px;
  }
  .card-img-overlay {
    top: initial;
    padding: 0.5rem;
  }
}

.upload-box {
  &.dropzone {
    border: thin dashed;
    border-color: var(--color-primary);
    border-radius: var(--border-radius-lg);
    color: var(--color-primary);
    background: none;
    margin: auto;
    min-height: 194px;
    padding: 20px 16px;

    &:hover {
      background: var(--color-hover);
    }

    a {
      text-align: center;
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.vue-dropzone > .dz-preview .dz-details {
    border-radius: 20px;
    background-color: var(--color-primary);
    color: var(--color-on-primary);
  }
}
